import React from "react";
import {Link} from 'react-router-dom';
import * as FormFields from 'components/formFields';
import * as All from 'components/imports';
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required(),  
});

const Footer = () => {

  const {register, handleSubmit, errors } = All.useForm({
    resolver: All.yupResolver(schema),   
    });

const submit = (data) => {
  alert(data);
}

  return (
    <>
      <div className="footer" >
       <div className="container">
       <div className="row">
          <div className="col-md-4 ">
         <div className="left-side">
         <div>
          <Link to="about">About</Link>
          </div>
          <div>
          <Link to="merchant">I'm a merchant</Link>
          </div>
          <div>
          <Link to="shopping">Interested in shopping</Link>
          </div>
         </div>
          </div>
          <div className="col-md-8 ">
         <div className="right-side">
         <h3>NEWSLETTER</h3>
          <p>
          Sign up to Kredify news and get notified of new store alerts, updates and special offers.
          </p>
          <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-md-8">
            <FormFields.Input 
             type="email"
              name="email" 
              errors={errors}
              placeholder="Your email"
             extClass="input"
              ref={register} 
             />
            </div>
            <div className="col-md-4">
            <button className="w3-btn">Subscribe</button>
            </div>
            </div>
         </form>  
         </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 copy-right">
          Copyright <span className="fa fa-copyright"></span> 2020 Kredify
          </div>
        </div>
       </div>

      </div>
    </>
  );
}

export default Footer;