import {MDBIcon} from 'mdbreact';
import React, {useState, useRef} from 'react';


const AdminSidebar = () => {
    const [showFirst, setShowFirst] = useState();
    const [show, setShow] = useState();

    return (
        <>
        <div className="navbar-1">
            <div className="bars-box">
            <MDBIcon icon="bars" className="bars"/>
            </div>
            <ul>
                <li>Users</li>
                <li>Others</li>
                <li className="parent">
                <span onClick={() => setShowFirst(!showFirst)} className="clearfix">
                <span >Option1</span> 
                <span> <MDBIcon icon="angle-right ml-5" className={`icon-1 ${showFirst ? 'rotate' : ''}`}/> </span>
                </span>
                <ul className={`children ${showFirst ? 'show-first' : ''}`}>
                    <li>Option2</li>
                    <li>Option3</li>
                    <li>Option4</li>
                </ul>
                </li>
                <li className="parent">
                <span onClick={() => setShow(!show)}>OptionA  
                <MDBIcon icon="angle-right" className="icon-2"/></span>
               
                <ul className={`children  ${show ? 'show' : ''}`} >
                    <li>OptionB</li>
                    <li>OptionC</li>
                    <li>OptionD</li>
                </ul>
                </li>
                <li>Option5</li>
                <li>Option6</li>
            </ul>
        </div>
        </>
    )
}


export default AdminSidebar;