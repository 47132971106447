import React from 'react';
import * as FormFields from 'components/formFields';
import * as All from 'components/imports';
import * as RouteLinks from 'components/routeLinks';
import * as Endpoints from 'components/endPoints';
import * as yup from "yup";
import {password, login} from 'store/actions/userActions';


const schema = yup.object().shape({
  password: yup.string().required(),  
});

const PasswordForm = () => {
    const {user, isLogin} = All.useSelector(All.usersSelector);
    const dispatch = All.useDispatch();
    const history = All.useHistory();

    const {register, handleSubmit, errors } = All.useForm({
        resolver: All.yupResolver(schema),   
        });
  
    const submit = async(data) => {
            if(sessionStorage.getItem('email') && (sessionStorage.getItem('isNew') === 'false')){
               
                dispatch(login({
                    body: {...data, email: sessionStorage.getItem('email')}, url: Endpoints.Login

                })).then(All.unwrapResult).then(() => {
                    All.notify.show('Successfully logged in', 'success', 2000);
                    history.push('/auth/dashboard');
                  
                });
            }else{
                dispatch(password({
                    body: {...data, registrationId: user.regId ?? sessionStorage.getItem("regId")},
                    url: Endpoints.CreatePassword
                   })).then(All.unwrapResult)
                   .then(() => {
                       history.push(RouteLinks.AddUserInfo);
                   }); 
            }      
         }
         
         React.useEffect(() => {
           
             let isMounted = true;
             if(isMounted){
                 /**
                     * redirect user to dashboard if logged in
                     */
                    if(isLogin){
                        history.push('/auth/dashboard');
                    }
                    /**
                     * redirects user to login page if not logged in
                     */
                    if(sessionStorage.getItem('regId') === 'undefined' && 
                        (sessionStorage.getItem('isNew') === 'true')){
                        history.push(RouteLinks.EmailLogin);
                    }
               }
            return () => {isMounted = false}
        });               
       
    return (
        <>
       
          <All.LoginLayout>
        <div>
            <div className="container" >
                <div className="row">
                    <div className="col-md-6 mx-auto ">
                        <div className="">
                            <form className="form user-login-form" onSubmit={handleSubmit(submit)}>
                                <h4>Create an account</h4>
                                <p>
                                    Choose a password to secure your account
                                </p>
                                <FormFields.Input 
                                    type="password"
                                    placeholder="Password"
                                    errors={errors}
                                    name="password"
                                    extClass="input"
                                    ref={register}
                                />
                                <p>
                                <FormFields.Button 
                                     text="Continue"
                                />
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </All.LoginLayout>
        </>
    )
}


export default PasswordForm;