/**
 * Only file for all the links in the app.
 */

const Home = '/';
const CheckoutToken = '/checkout/:token';
const EmailLogin = '/login-email';
const PhoneNumber = '/phone-number';
const VerifyPhoneNumber = '/verify/phone-number';
const PasswordLogin = '/login-password';
const AddUserInfo = '/add-consumer-info';
const Dashboard = '/dashboard';
const Sidebar = '/sidebar';

export {
    Home,
    CheckoutToken,
    EmailLogin,
    PhoneNumber,
    VerifyPhoneNumber,
    PasswordLogin,
    AddUserInfo,
    Dashboard,
    Sidebar
}