import * as Routers from 'components/routes';
import {Link} from 'react-router-dom';

const Header = () => {
    return (
        <>
        <Routers.NavBar />
        <div className="container text-center">
        <Link to="/">
              <img src="/assets/kredify-logo.png" alt="kredify-log" className="img-fluid logo"
                  width="200"
              />
         </Link>
        </div>
        </>

    )
}

export default Header