import BaseRoute from 'components/baseRoute';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.scss';
import './styles/w3.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Notifications from 'react-notify-toast';

AOS.init();


function App() {
  return (
    <div className="App">
    <Notifications options={{zIndex: 200, top: '50px'}}/>
    <BaseRoute />
    </div>
  )
}

export default App;
