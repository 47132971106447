import axios from 'axios';
import { startLoading, endLoading} from 'store/slices/loadersSlice';
import getErrors from 'helpers/getErrors';
import {setErrors} from 'store/slices/errorsSlice';

const apiClient = axios.create({
    baseURL: 'https://consumer-api.kredify.net',
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
    }
});

/**
 * base http request for all post request with the same implementation
 * @param {object} data 
 * @param {object} thunk 
 */
export const postHttp =  async (data, thunk) => {
    const {
        body,
        url,
    } = data;
    thunk.dispatch(startLoading()); 
    try {
     const res = await apiClient({
       method: 'POST', 
       url: url, 
       data: JSON.stringify(body),
                });
      thunk.dispatch(endLoading());
      return res;  
    } catch (error) {
      thunk.dispatch(endLoading());
      thunk.dispatch(setErrors(getErrors(error)));
      return thunk.rejectWithValue(error);
    }
  }

  
  /**
 * base http request for all get request with the same implementation
 * @param {object} data 
 * @param {object} thunk 
 */
  export const getHttp =  async (data, thunk) => {
    const {
        url,
        params
    } = data;
    thunk.dispatch(startLoading()); 
    try {
     const res = await apiClient({
       method: 'GET', 
       url: url, 
       params: params
                });
      thunk.dispatch(endLoading());
      return res;  
    } catch (error) {
      thunk.dispatch(endLoading());
      thunk.dispatch(setErrors(getErrors(error)));
      return thunk.rejectWithValue(error);
    }
  }

export default apiClient;