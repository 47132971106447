import {errorsSelector} from 'store/slices/errorsSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {AlertIcon} from 'components/icons';
import {clearErrors} from 'store/slices/errorsSlice';
import * as All from 'components/imports';

export const ShowError = () => {
    const {error} = useSelector(errorsSelector);
    const dispatch = useDispatch()
    const hideError = () => {
    dispatch(clearErrors())
    }
    useEffect(() => {
        return () => {
        }
    }, [error]);

    return(
        <>
        <div className={`errors`} >
           <div className={`error-content w3-card-2 `}>
           <div>
            <h3><AlertIcon /></h3>
        </div>
           <div>
           {
                error?.error && error?.error.map((e, i ) => (<p key={i}>{e}</p>))
            }
           </div>
           <div>
                <button className="btn btn-primary" onClick={hideError}>Ok</button>
           </div>
           </div>
        </div>
        </>
    )
}


export const NotifyError = () => {
    const {error} = useSelector(errorsSelector)
    const dispatch = useDispatch()
    const hideError = () => {
    dispatch(clearErrors())
        All.notify.hide()
    }
    useEffect(() => {
        return () => {
        }
    }, [error])
    return All.notify.show(
        <div>
          {
             error && error.map((e, i ) => (<p key={i}>{e}</p>))
        }
          <button onClick={hideError}><span className="fa fa-close"></span></button>
        </div>, "error", -1
      );
}