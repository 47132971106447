import React from 'react';
import * as FormFields from 'components/formFields';
import * as All from 'components/imports';
import * as RouteLinks from 'components/routeLinks';
import * as Endpoints from 'components/endPoints';
import * as yup from "yup";
import {verifyPhone} from 'store/actions/userActions';



const schema = yup.object().shape({
 verificationCode: yup.string().required(),
});


const VerifyPhone = () => {

const {user, isLogin} = All.useSelector(All.usersSelector);

const dispatch = All.useDispatch();
const history = All.useHistory();
    const {register, handleSubmit, errors } = All.useForm({
        resolver: All.yupResolver(schema),   
        })
const submit = async(data) => {
    dispatch(verifyPhone({
       body: { ...data,
        registrationId: user.regId ?? sessionStorage.getItem("regId"),
        phoneNumber: user.phoneNumber ?? sessionStorage.getItem("phone")
    },
    url: Endpoints.PhoneNumberVerification
    })).then(All.unwrapResult)
    .then(() => {
        All.notify.show('Phone number verified successfully', 'success', 2000);
       history.push(RouteLinks.PasswordLogin);
    })
    }
 
    React.useEffect(() => {
        /**
         * redirect user to dashboard if logged in
         */
        if(isLogin){
            history.push('/auth/dashboard');
        }

        /**
         * redirects user to login page if not logged in
         */
        if(sessionStorage.getItem('regId') === 'undefined'){
            history.push(RouteLinks.EmailLogin);
        }
    });
    return (
        <>
        
        <All.LoginLayout>
        <div >
            <div className="container" >
                <div className="row">
                    <div className="col-md-6 mx-auto ">
                        <div className="">
                            <form className="form user-login-form" onSubmit={handleSubmit(submit)}>
                                <h4>Verify mobile number</h4>
                                <p>
                                    Place the code sent to your mobile number to complete verification
                                </p>
                                <FormFields.Input 
                                    type="text"
                                    placeholder="Code"
                                    errors={errors}
                                    name="verificationCode"
                                    extClass="input"
                                    ref={register}
                                />
                                <p>
                                  <FormFields.Button 
                                      text="Verify"
                                  />  
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </All.LoginLayout>
        </>
    )
}


export default VerifyPhone;