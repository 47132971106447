/**
 * All API endpoints consumer in app.
 */

const PhoneNumberVerification = '/auth/phone-number/verify';
const CreatePhoneNumber = '/auth/phone-number';
const CreatePassword = '/auth/password';
const Login = '/auth/login';
const CreateAddress = '/auth/address';


const GetPaymentURL = '/checkout/payment-url';
const GetSchedules = '/checkout/schedules';


export {
    PhoneNumberVerification,
    CreatePhoneNumber,
    CreatePassword,
    Login,
    CreateAddress,
    GetPaymentURL,
    GetSchedules,
}