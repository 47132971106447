import { createSlice, createSelector} from '@reduxjs/toolkit';


/**
 * reducer for errors
 */
const errorsSlice = createSlice({
    name: 'errors',
    initialState: {
        error: null,
        sessionError: null,
    },
    reducers: {
    setErrors: (state, action) => {
             state.error = action.payload;
             if(action.payload.code === 419 || 401){
                state.sessionError = 'Session expired';
                sessionStorage.setItem('isLogin', false);
             }
        },
     clearErrors: (state) => {
            state.error = null;
            state.sessionError = null
        }
    }
})

export const {
    setErrors,
    clearErrors
} = errorsSlice.actions

export const errorsSelector = createSelector(
    (state) => ({
         error : state.errors.error,
         sessionError: state.errors.sessionError
     }),
 
     (state) => state
 )
 
 export default errorsSlice.reducer