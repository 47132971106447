
/**
 * get the next payment schedule
 * @param {object} installments 
 */

const nextPayment = (installments) => {
   let plans = transformItems(installments);
    let next;
    let isOverDue = plans.filter(inst => inst.isOverDue === true && !inst.isPaid);
    if(isOverDue.length === 0){
     let unPaid = plans.filter(inst => inst.isPaid === false);
        next = unPaid.find(plan => plan.dueDate === minDate(unPaid));
    }else{
        next = isOverDue.find(plan => plan.dueDate === minDate(isOverDue));
    }

   return next; 
}

/**
 * transforms the installments
 * @param {object} items 
 */
export const transformItems = (items) => {
 return items?.plan.map(item => ({
        amount: item.amount.value,
        currency: item.amount.currency,
        dueDate: new Date(item.dueDate),
        invoiceId: item.invoiceId,
        isPaid: item.isPaid,
        isOverDue: item.isOverDue,
    }))
}

/**
 * get the minimum date
 * @param {array} plans 
 */
export const minDate = (plans) => {
    let dates = plans.map(plan => plan.dueDate);
    if(dates.length > 0){
        return dates.reduce((a, b) => {
            return a < b ? a : b; 
       }) 
    }else{
        return null;
    }
   
}


export default nextPayment;

