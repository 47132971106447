import React from 'react';
import * as FormFields from 'components/formFields';
import * as All from 'components/imports';
import * as RouteLinks from 'components/routeLinks';
import * as Endpoints from 'components/endPoints';
import * as yup from "yup";
import "yup-phone";
import {phoneAction} from 'store/actions/userActions';


const schema = yup.object().shape({
  phoneNumber: yup.string().phone("GH").required(),  
});

const PhoneForm = () => {
    const {user, isLogin} = All.useSelector(All.usersSelector);
    

    const dispatch = All.useDispatch();
    const history = All.useHistory();
    const {register, handleSubmit, errors } = All.useForm({
        resolver: All.yupResolver(schema),   
        });
  
    const submit = (data) => {
         dispatch(phoneAction({
              body: {...data, registrationId: user.regId ?? sessionStorage.getItem("regId")},
                url: Endpoints.CreatePhoneNumber
            })).then(All.unwrapResult)
            .then(() => {
                history.push(RouteLinks.VerifyPhoneNumber);
                })
            }
            React.useEffect(() => {
                /**
                 * redirect user to dashboard if logged in
                 */
                if(isLogin){
                    history.push('/auth/dashboard');
                }
                /**
                 * redirects user to login page if not logged in
                 */
                if(sessionStorage.getItem('regId') === 'undefined'){
                    history.push(RouteLinks.EmailLogin);
                }
            });        

    return (
        <>
        
        <All.LoginLayout>
        <div>
       
            <div className="container" >
                <div className="row">
                    <div className="col-md-6 mx-auto ">
                        <div className="">
                                <form className="form user-login-form" onSubmit={handleSubmit(submit)}>
                                 <h4>Create an account</h4>
                                <p>
                                    A verification code will be sent to your mobile number shortly.
                                </p>
                                <FormFields.Input 
                                    type="tel"
                                    placeholder="Enter phone number"
                                    errors={errors}
                                    name="phoneNumber"
                                    extClass="input"
                                    ref={register}
                                />
                                <p>
                                <FormFields.Button 
                                      text="Continue"
                                  /> 
                                </p>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </All.LoginLayout>
        </>
    )
}


export default PhoneForm;