import React from 'react';
import * as FormFields from 'components/formFields';
import {Link, useParams} from 'react-router-dom';
import * as All from 'components/imports';
import * as RouteLinks from 'components/routeLinks';
import * as yup from "yup";
import {emailScan} from 'store/actions/userActions';


const schema = yup.object().shape({
 email: yup.string().email().required(),  
});

const EmailForm = () => {

const dispatch = All.useDispatch();
const history = All.useHistory();
const {isLogin} = All.useSelector(All.usersSelector);

    const {token} = useParams();

    const {register, handleSubmit, errors } = All.useForm({
            resolver: All.yupResolver(schema),
        });

const submit = async(data) => {
    dispatch(emailScan({email: data}))
    .then(All.unwrapResult)
    .then(res => {
        const {hasPhoneNumber, 
            hasPhoneNumberVerified,
            hasPassword,
            isNew,
            hasAddress
        } = res.data.data;
     if(!isNew){
        history.push(RouteLinks.PasswordLogin);
    }else if(hasPhoneNumber && hasPhoneNumberVerified && hasPassword && !hasAddress){
        history.push(RouteLinks.AddUserInfo)
    } 
    else if(hasPhoneNumber && hasPhoneNumberVerified && !hasPassword){
         history.push(RouteLinks.PasswordLogin)
     }
    else{
        history.push(RouteLinks.PhoneNumber)
        }
    });
    }
       
    React.useEffect(() => {
        if(token){
            sessionStorage.setItem("token", token);  
        }
        if(isLogin){
            history.push('/auth/dashboard');
        }
        return () => {}
    });

    return (
        <>
         
        <All.LoginLayout>
        <div>
      
            <div className="container" >
                <div className="row">
                    <div className="col-md-6 mx-auto ">
                        <div className="">
                            <form className="form user-login-form" onSubmit={handleSubmit(submit)}>
                                <p>
                                    Enter your email to login or to create a new
                                    account
                                </p>
                                <FormFields.Input 
                                    type="email"
                                    placeholder="Email address"
                                    errors={errors}
                                    name="email"
                                    extClass="input"
                                    ref={register}
                                />
                                <p>
                                  <FormFields.Button 
                                      text="Continue"
                                  />  
                                </p>
                                <p className="retailor-login">
                                <Link to="/merchant-login">Login as a retailer instead</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </All.LoginLayout>
        </>
    )
}


export default EmailForm;