
/**
 * returns errors
 * @param {object} error 
 */

const getErrors = (error) => {
    if(error.response){
      let err = error.response.data.error.data;
      if(Array.isArray(err) && err?.length > 0){
        return {
          error: error.response.data.error.data.flat(), 
          code: error.response.status
        }
     }
     else{
        return {
          error: ['Something went wrong!'],
          code: error.response.status
        };
     }
    }
    if(error.request){
      return {
        error: ['Something went wrong!'],
        code: error.request.status
      };
    }
    return {
      error: ['Something went wrong!'],
      code: 'unknown'
    };
  }
  

  export default getErrors;