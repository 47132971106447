import React from "react";
import * as All from 'components/imports';
import {Link} from 'react-router-dom';
import {logout} from 'store/actions/userActions';
import * as RouteLinks from 'components/routeLinks';

const NavBar = () => {
  const {isLogin} = All.useSelector(All.usersSelector);
const {toggleShow, show, target} = All.useShow();
const dispatch = All.useDispatch();

const history = All.useHistory();

const onLogout = () => {
    dispatch(logout()).then(All.unwrapResult)
    .then(() => {
      All.notify.show('Successfully logged out', 'success', 2000)
        history.push(RouteLinks.Home);
      })
    }
React.useEffect(() => {}, [isLogin])
    return (
        <>
          <div className="w3-card home-nav">
             
              <div className="container ">
              
              <div className="d-flex">
              <ul className="mr-auto ">
              <Link to={RouteLinks.Home}>
              <img src="/assets/kredify-logo.png" alt="kredify-log" className="img-fluid logo"/>
             </Link>
              </ul>
        {isLogin ?
               (
                <ul className="nav-parent">
                <li>
                  
                    <span className="fa fa-user w3-btn" onClick={() => toggleShow("home-nav")}></span>
                 
                  <ul className={` ${show && target === "home-nav" ? 'show' : ''}`}>
                    <li>
                    Profile
                    </li>
                    <li onClick={onLogout}>
                     Logout
                    </li>
                  </ul>
                </li>
              </ul>
              ) : (
                <ul className="nav-parent ">
                <li>
                  <Link to="/auth/email/scan">
                    Log In
                  </Link>
                </li>
              </ul>
              )}
              </div>
              </div>
            
          </div>
        </>
        );
}



export default NavBar;