import { createAsyncThunk} from '@reduxjs/toolkit';
import { startLoading, endLoading} from 'store/slices/loadersSlice';
 import getErrors from 'helpers/getErrors';
 import apiClient, {postHttp, getHttp} from 'helpers/apiClient';
 import {setErrors} from 'store/slices/errorsSlice';



 /**
 * scans for email in the db and set cookies for auth
 * @param {object} data 
 * @param {object} thunk 
 */

  export const emailScan = createAsyncThunk(
    'users/emailScan',
      async (data, thunk) => {
      const {email} = data;
      thunk.dispatch(startLoading()); 
      try {
       await apiClient({
          method: 'GET', 
          url: '/sanctum/csrf-cookie'
        }); 

       const res = await apiClient({
         method: 'POST', 
         url: '/auth/email/scan', 
         data: JSON.stringify(email),
                  });
        thunk.dispatch(endLoading());
        return res;  
      } catch (error) {
        thunk.dispatch(endLoading());
        thunk.dispatch(setErrors(getErrors(error)));
        return thunk.rejectWithValue(error);
      }
    }
  )

  /**
 * saving phone 
 * @param {object} data 
 * @param {object} thunk 
 */
  export const phoneAction = createAsyncThunk(
    'users/phoneAction',
      async (data, thunk) => {
      return postHttp(data, thunk);
    }
  );

  /**
 * verifies phone number
 * @param {object} data 
 * @param {object} thunk 
 */

  export const verifyPhone = createAsyncThunk(
    'users/verifyPhone',
      async (data, thunk) => {
        return postHttp(data, thunk);
    }
  );
  

  /**
 * creates password
 * @param {object} data 
 * @param {object} thunk 
 */
  export const password = createAsyncThunk(
    'users/password',
      async (data, thunk) => {
      return postHttp(data, thunk);
    }
  );


  /**
 * creates user address
 * @param {object} data 
 * @param {object} thunk 
 */
  export const address = createAsyncThunk(
    'users/address',
      async (data, thunk) => {
        return postHttp(data, thunk);
    }
  );

  /**
 * logs user in
 * @param {object} data 
 * @param {object} thunk 
 */
  export const login = createAsyncThunk(
    'users/login',
      async (data, thunk) => {
        return postHttp(data, thunk);
    }
  );

  /**
 * logs user out
 * @param {object} data 
 * @param {object} thunk 
 */

  export const logout = createAsyncThunk(
    'users/logout',
      async (_, thunk) => {
      thunk.dispatch(startLoading()); 
      try {
       const res = await apiClient({
         method: 'POST', 
         url: '/auth/logout', 
                  });
        thunk.dispatch(endLoading());
        return res;  
      } catch (error) {
        thunk.dispatch(endLoading());
        thunk.dispatch(setErrors(getErrors(error)));
        return thunk.rejectWithValue(error);
      }
    }
  );


  /**
 * get payment schedules
 * @param {object} data 
 * @param {object} thunk 
 */
  export const schedules = createAsyncThunk(
    'users/schedules',
      async (data, thunk) => {
        return getHttp(data, thunk);
    }
  );

  /**
 * get payment url
 * @param {object} data 
 * @param {object} thunk 
 */
  export const getPayUrl = createAsyncThunk(
    'users/getPayUrl',
      async (data, thunk) => {
        return getHttp(data, thunk);
    }
  );