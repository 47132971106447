
/**
 * convert date of each installment into the form day, date month
 * @param {date} param0 
 */

const GetDate = ({date}) => {
let d = new Date(date);
let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
let day = days[d.getDay()];
const months = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
let month = months[d.getMonth()];

const dte = d.getDate();
return (
    <>
    <i>{day}, {dte} {month}</i>
    </>
)
}


export default GetDate;