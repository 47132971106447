import React from 'react';
import {useHistory} from 'react-router-dom';
import LoginLayout from 'components/public/loginLayout';
import * as All from 'components/imports';
import {schedules, getPayUrl, logout} from 'store/actions/userActions';
import useShow from 'helpers/useShow';
import GetDate from 'helpers/getDate';
import {RotateLoader} from 'components/loaders';
import * as Endpoints from 'components/endPoints';
import nextPayment, {transformItems, minDate} from 'helpers/nextPayment';


const Dashboard = () => {
const { show, target, toggleShow} = useShow();
const {plans, isLogin} = All.useSelector(All.usersSelector);
const {loading} = All.useSelector(All.loadersSelector);


    
const dispatch = All.useDispatch();

const items = Object.keys(plans).length > 0 && transformItems(plans);
const isPaymentComplete = Object.keys(plans).length > 0 && items?.some(item => item.isPaid === false);
const history = useHistory();
/**
 * redirect to payment url and logs user out
 */
const getPaymentUrl = () => {
        if(!isPaymentComplete){
            return;
        }
   
    const next = nextPayment(plans); 
    const invoiceId = next.invoiceId;
    dispatch(getPayUrl({
        url: Endpoints.GetPaymentURL,
        params: {token: sessionStorage.getItem('token'), invoiceId: invoiceId}
    })).then(All.unwrapResult).then(res => {
        window.location.href = res.data.data.paymentURL; 
        dispatch(logout()) 
       
        })
    .catch((e) => e);
}

React.useEffect(() => {
    /**
     * store token as a cookie
     */
    if(sessionStorage.getItem('token')){
        const token = sessionStorage.getItem('token');
        dispatch(schedules({
            url: Endpoints.GetSchedules,
            params: {token: token}
        }))
        .catch((e) => e)
    }

    /**
     * logs user out when session expires
     */

    if(!isLogin){
        history.push('/auth/dashboard');
    }
}, [history, isLogin, dispatch]);


    return (
        <>
        <LoginLayout>
            <div className="">
            <div className="container">
               <div className="dashboard">
               <div className="row">
                    <div className="col-md-6 mx-auto text-center">
                        <div >
                        {loading ? <RotateLoader /> : 
                            items?.length > 0 && (
                                <div className="checkout">
                            <h3>{plans.storeName}</h3>
                            <p>Installments by kredify</p>
                                <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-between">
                                        <div><span className="fas fa-shopping-cart mr-2"></span>Your total order</div>
                                        <div className="font-weight-bold">{plans.totalAmount?.currency} {plans.totalAmount?.value}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div></div>
                                        <div className="toggle-installments">
                                        <button onClick={() => toggleShow('first')} >
                                        Installments
                                        {show && target === 'first' ? <i className="fas fa-angle-up ml-2"></i> :
                                        <i className="fas fa-angle-down ml-2"></i>
                                        } 
                                        </button>
                                        </div>
                                    </div>
                                    {/**
                                        iterate over items and display next item for payment
                                     */}
                                    {
                                        items.map((item, i) => {
                                        const next = nextPayment(plans);
                                        const today = new Date().toLocaleDateString();
                                        const unPaid = items.filter(inst => inst.isPaid === false)
                                        if(item.isOverDue && new Date().toLocaleDateString(item.dueDate) === today){
                                            return (
                                        <div key={i} className={`justify-content-between ${show && target === 'first' ? 'next' : 'd-flex'}`}>
                                        <div><i className="fas fa-coins mr-2"></i>Due today</div>
                                        <div className="font-weight-bold">{item.currency} {item.amount}</div>
                                        </div> 
                                                )
                                        }else if(item.dueDate === minDate(unPaid)){
                                            return (
                                        <div key={'next'} className={`justify-content-between ${show && target === 'first' ? 'next' : 'd-flex'}`}>
                                        <div><i className="fas fa-coins mr-2"></i> Due <GetDate date={next.dueDate}/></div>
                                        <div className="font-weight-bold">{next.currency} {next.amount}</div>
                                        </div> 
                                                )
                                        }
                                    })
                                    }
                                    {/**
                                        display the list of items
                                     */}
                                    <div className={`d-flex flex-column with-children ${show && target === 'first' ? 'show' : ''}`}>
                                        {items.map((item, i) => {
                                            const today = new Date().toLocaleDateString();
                                            if(item.isPaid){
                                            return (
                                            <div className="d-flex justify-content-between" key={i}>
                                            <div  className="font-weight-bold"> <span className="paid">{i + 1}</span>Paid</div>
                                            <div className="font-weight-bold"> {item.currency} {item.amount}</div>
                                            </div>
                                                )
                                            }else if(item.isOverDue && new Date().toLocaleDateString(item.dueDate) === today){
                                                return (
                                            <div className="d-flex justify-content-between" key={i}>
                                            <div className="font-weight-bold"> <span className="next today">{i + 1}</span>Due today</div>
                                            <div className="font-weight-bold">{item.currency} {item.amount}</div>
                                            </div>  
                                                )
                                            }else if(!item.isOverDue && !item.isPaid){
                                                return(
                                                    <div className="d-flex justify-content-between" key={i}>
                                                 <div className=""> <span className="normal">{i + 1}</span>
                                                 <GetDate date={item.dueDate}/>
                                                 </div>
                                                    <div className="">{item.currency} {item.amount}</div>
                                                </div>  
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                                <button className="pay-btn w3-btn" onClick={getPaymentUrl}>
                                {isPaymentComplete ? 'Proceed to pay' : 'Payments completed'}
                                </button>
                                </div>
                        )}
                        
                        </div>
                    </div>
                </div>
               </div>
            </div>
            </div>
        </LoginLayout >
        </>
    )
}


export default Dashboard;