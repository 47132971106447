import React from 'react';
import * as FormFields from 'components/formFields';
import * as All from 'components/imports';
import * as Endpoints from 'components/endPoints';
import * as RouteLinks from 'components/routeLinks';
import * as yup from "yup";
import {address} from 'store/actions/userActions';


const schema = yup.object().shape({
 legalFirstName: yup.string().required(),
 legalLastName: yup.string().required(),
legalOtherNames: yup.string(),
gMapAddress: yup.string().required(),
region: yup.string().required(),
landmark: yup.string(),  
});

const Address = () => {
const {user, isLogin} = All.useSelector(All.usersSelector);
const dispatch = All.useDispatch();
const history = All.useHistory();


 const {register, handleSubmit, errors } = All.useForm({
        resolver: All.yupResolver(schema),   
        });

const submit = async(data) => {
    dispatch(address({
        body: {...data, registrationId: user.regId ?? sessionStorage.getItem("regId")},
        url: Endpoints.CreateAddress
    })).then(All.unwrapResult)
    .then(() => {
        All.notify.show('Successfully logged in', 'success', 2000);
        history.push('/auth/dashboard');
    });
    }
React.useEffect(() => {
    /**
     * redirect user to dashboard if logged in
     */
    if(isLogin){
        history.push('/auth/dashboard');
    }

    /**
     * redirects user to login page if not logged in
     */
    if(sessionStorage.getItem('regId') === 'undefined'){
        history.push(RouteLinks.EmailLogin);
    }
});
    return (
        <>
      
        <All.LoginLayout>
        <div >
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto ">
                        <div className="">
                            <form className=" form address-form" onSubmit={handleSubmit(submit)}>
                                
                                  <h3 className="text-center">Provide your details</h3>
                                    <hr />
                                    <h4>Name</h4>
                                    <div className="row">
                                        <div className="col-md-6">
                                    <FormFields.Input 
                                    type="text"
                                    title="Legal First Name"
                                    placeholder="First name"
                                    errors={errors}
                                    name="legalFirstName"
                                    extClass="input"
                                    ref={register}
                                    /> 
                                    </div>
                                    <div className="col-md-6">
                                    <FormFields.Input 
                                    type="text"
                                    title="Legal Last Name"
                                    placeholder="Last name"
                                    errors={errors}
                                    name="legalLastName"
                                    extClass="input"
                                    ref={register}
                                />
                                    </div>
                                    </div>
                                <FormFields.Input 
                                    type="text"
                                    title="Other Legal Names"
                                    placeholder="Other names (optional)"
                                    errors={errors}
                                    name="otherLegalNames"
                                    extClass="input"
                                    ref={register}
                                />
                                <h4>Address</h4>
                                <FormFields.Input 
                                    type="text"
                                    title="Google Map Address"
                                    placeholder="Google map address"
                                    errors={errors}
                                    name="gMapAddress"
                                    extClass="input"
                                    ref={register}
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                    <FormFields.Input 
                                    type="text"
                                    title="Region"
                                    placeholder="Region"
                                    errors={errors}
                                    name="region"
                                    extClass="input"
                                    ref={register}
                                />
                                </div>
                                <div className="col-md-6">
                                <FormFields.Input 
                                    type="text"
                                    title="Landmark"
                                    placeholder="Landmark (optional)"
                                    errors={errors}
                                    name="landmark"
                                    extClass="input"
                                    ref={register}
                                />
                                </div>
                                </div>
                               
                                  <div className="col-md-6">
                                  <FormFields.Button 
                                      text="Continue"
                                  />  
                                  </div>
                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </All.LoginLayout>
        </>
    )
}


export default Address;