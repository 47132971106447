import React from 'react';
import * as FormFields from 'components/formFields';
import * as All from 'components/imports';
import * as Endpoints from 'components/endPoints';
import * as yup from "yup";
import {login} from 'store/actions/userActions';


const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),  
});

const Login = () => {
    const {isLogin} = All.useSelector(All.usersSelector);
    const dispatch = All.useDispatch();
    const history = All.useHistory();
    const {register, handleSubmit, errors } = All.useForm({
        resolver: All.yupResolver(schema),   
        })
  
        const submit = async(data) => {
            dispatch(login({body: data, url: Endpoints.Login
            })).then(All.unwrapResult)
            .then(() => {
                All.notify.show('Successfully logged in', 'success', 2000)
                history.push('/auth/dashboard');
            })       
         }
         React.useEffect(() => {
            /**
             * redirect user to dashboard if logged
             */
            if(isLogin){
                history.push('/auth/dashboard');
            }
        });

    return (
        <>
        
         <All.LoginLayout>
        <div>
       
            <div className="container" >
                <div className="row">
                    <div className="col-md-6 mx-auto ">
                        <div className="">
                            <form className="form user-login-form" onSubmit={handleSubmit(submit)}>
                                <h4 className="text-center mb-3">
                                    Login
                                    <hr />
                                </h4>
                                <FormFields.Input 
                                    type="email"
                                    placeholder="Enter email"
                                    errors={errors}
                                    name="email"
                                    extClass="input"
                                    ref={register}
                                />
                                <FormFields.Input 
                                    type="password"
                                    placeholder="Enter password"
                                    errors={errors}
                                    name="password"
                                    extClass="input"
                                    ref={register}
                                />
                                <p>
                                <FormFields.Button 
                                      text="Login"
                                    
                                  /> 
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </All.LoginLayout>
        </>
    )
}


export default Login;