import {useSelector} from 'react-redux'
import {BallClipRotate, BallBeat} from 'react-pure-loaders';
import {loadersSelector} from 'store/slices/loadersSlice';
import Skeleton from 'react-loading-skeleton';
import * as All from 'components/imports';


export const RotateLoader = ({text}) => {
    const {loading} = useSelector(loadersSelector)
    return (
    <>
    <div className={`${loading ? 'full-loader' : ''}`}>
     <span>{text}</span>
        <BallClipRotate 
        loading={loading}
        color="#000"
        />
    </div>
    </>
)
}





export const DashboardLoader = () => {
  return (
      <>
  
  <div className="loader">
  <Skeleton height={40} width={200}/>
  <div>
  <Skeleton height={30} width={150}/>
  </div>
  <div className="d-flex flex-column">
    <div className="d-flex justify-content-between">
    <Skeleton height={30} width={100}/>
    <Skeleton height={30} width={100}/>
    </div>
    <div className="d-flex justify-content-between">
    <Skeleton height={30} width={100}/>
    <Skeleton height={30} width={100}/>
    </div>
    <div className="d-flex justify-content-between">
    <Skeleton height={30} width={100}/>
    <Skeleton height={30} width={100}/>
    </div>
    <div className="d-flex justify-content-between">
    <Skeleton height={30} width={100}/>
    <Skeleton height={30} width={100}/>
    </div>
    <div className="d-flex justify-content-between">
    <Skeleton height={30} width={100}/>
    <Skeleton height={30} width={100}/>
    </div>
  </div>
  </div>
  
      </>
  )
}


export const FadeLoader = () => {
const {loading} = useSelector(All.loadersSelector);
return(
  <>
    <BallBeat
    loading={loading}
    color="#28a745"
  />
  </>
)
    

}