import {combineReducers} from 'redux';
import usersReducer from './usersSlice';
import errorsReducer from './errorsSlice'
import loadersReducer from './loadersSlice'

/** 
 * root reducer
*/
 const rootReducer = combineReducers({ 
    users: usersReducer,
    errors: errorsReducer,
    loaders: loadersReducer,
   });


 export default rootReducer;  