
import useShow from '../../helpers/useShow';


const TopNav = () => {
   const {toggleShow, show, target} = useShow();
    return (
        <>
        <div className="top-nav card">
            <ul>
                <li>
                <span className="tool" onClick={() => toggleShow('1')}>Option1</span>
                <ul className={`children-box ${show && target === "1" ? 'show' : ''}`} >
                <li>Option1</li>
                <li>Option1</li>
                <li>Option1</li>
                <li>Option1</li>
                </ul>
                </li>
                <li>Option1</li>
                <li>
                <span onClick={() => toggleShow('2')}>Option1</span>
                <ul className={`children-box ${show && target === "2" ? 'show' : ''}`} >
                <li>Option1</li>
                <li>Option1</li>
                <li>Option1</li>
                <li>Option1</li>
                </ul>
                </li>
                <li className="last">Option1</li>
                <li>Option1</li>
            </ul>
        </div>

        </>
    )
}


export default TopNav;