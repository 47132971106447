import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as Routers from 'components/routes';
import UserAuth from 'components/authRoutes/userAuth';
import * as RouteLinks from 'components/routeLinks'


const BaseRoute = () => {
    return (
        <>
            <Router>
                <Switch>

                    <Route exact path={RouteLinks.Home}>
                        <Routers.Home />
                    </Route>

                    <Route exact path={RouteLinks.CheckoutToken}>
                        <Routers.EmailForm />
                    </Route>

                    <Route exact path={RouteLinks.EmailLogin}>
                        <Routers.EmailForm />
                    </Route>

                    <Route exact path={RouteLinks.PhoneNumber}>
                        <Routers.PhoneForm />
                    </Route>

                    <Route exact path={RouteLinks.VerifyPhoneNumber}>
                        <Routers.VerifyPhone/>
                    </Route>

                    <Route exact path={RouteLinks.PasswordLogin}>
                        <Routers.PasswordForm />
                    </Route>

                    <Route exact path={RouteLinks.AddUserInfo}>
                        <Routers.Address />
                    </Route>

                    <Route exact path="/auth/login">
                        <Routers.Login />
                    </Route>

                    <UserAuth exact path="/auth/dashboard">
                        <Routers.Dashboard />
                    </UserAuth>

                    <Route path={RouteLinks.Sidebar}>
                        <Routers.TopNav />
                        <Routers.AdminSidebar />
                    </Route>

                </Switch>
            </Router>
        </>
    )
}


export default BaseRoute;