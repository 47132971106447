import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import Layout from 'components/layout';
import * as All from 'components/imports';
import * as RouteLinks from 'components/routeLinks';

const Home = () => {
const cookies = new All.Cookies();
const history = useHistory();

    React.useEffect(() => {
        if(cookies.get('isLogin') && cookies.get('XSRF-TOKEN')){
            history.push('/auth/dashboard');
        }
    })
    return (
        <>
        <Layout>
        <div className="body home">
        
        <div className="top " data-aos="fade-left">
            <div className="container">
              
                <div className="row">
                <div className="col-md-6 lft">
                        <h2>
                            Buy now <br />
                            pay later <br />
                            any where
                        </h2>
                        <p>
                        Afford high-priced products without breaking your bank.
                        </p>
                        <p>
                        Pay in installments for all your shopping.
                        </p>
                        <p>
                        <Link to={RouteLinks.EmailLogin}><button className="w3-btn">Sign Up Now</button></Link>
                        </p>
                    </div>
                    <div className="col-md-6 rht">
                        <img src="/assets/kred.jpg" alt="kredify" className="img-fluid card"/>
                    </div>
                </div>
                </div>
                </div>
                <div className="middle " data-aos="fade-right">
                <div className="container" >
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center">
                       <h2>
                       Why join us
                       </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="text-center">
                                <img src="/assets/join.svg" alt="join us"/>
                            </p>
                            <p>
                            With Kredify, anyone can first receive their purchase and pay off smartly, gradually and conveniently
                            </p>
                        </div>
                        <div className="col-md-6">
                        <p className="text-center">
                             <img src="/assets/join.svg" alt="join us"/>
                        </p>
                        <p>
                        Your customers will love this new way of payment.
                        Partner with Kredify to access the largest poll of excited shoppers and increase your revenue off the roof
                        </p>
                        </div>
                    </div>
                </div>
                </div>
            <div className="container">
                <div className="bottom" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <h2>What we think?</h2>
                        <h4>
                        Plan Designed Around You
                        </h4>
                        <p>
                        Everyone especially you, should have a payment plan 
                        specially designed for you. No more going broke to afford that furniture
                        </p>
                        <h4>
                        Highly Flexible
                        </h4>
                        <p>
                        With Kredify, we deliver to your door step and you spread the payments
                        over months. So flexible, you can do more with your money
                        </p>
                        <h4>
                        Checkout Anywhere
                        </h4>
                        <p>
                        Browse your favorite stores and checkout with Kredify to instantly create an account.
                         Grow a good score on your Account to unlock more features.
                        </p>

                        <p>
                        <Link to={RouteLinks.EmailLogin}><button className="w3-btn">Sign Up Now</button></Link>
                        </p>
                    </div>
                </div>
                </div>
            </div>
           
        </div>
        </Layout>
        </>
    )
}


export default Home;