import * as All from 'components/imports';

const Layout = ({children}) => {
    return (
        <>
            <All.Header />
            <div className="layout">
                {children}
               <footer>
            <All.Footer />
            </footer>
            </div>

        </>
    )
}


export default Layout;