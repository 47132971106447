import {forwardRef} from 'react';
import * as All from 'components/imports';





const Input = forwardRef((
    {title, name , step, type, placeholder, extClass, 
 errors, defaultValue, required}, ref
) => {
    return (
        <>
<div className="input-group">
        <label htmlFor={name}>{title}</label>
        <input type={type} 
        className={`w3-input w3-border w3-round-large ${extClass}`} 
        placeholder={placeholder} 
        aria-label={title} 
        name={name}
        step={step}
        defaultValue={defaultValue} 
        required={required}
         ref={ref}
        /> <br />
      
        </div>
        <p>
       <span className="error">{errors[name]?.message}</span>
       </p>
        </>
    );
  })


const Radio = forwardRef(({title,  errors, name, options, required, defaultChecked}, ref) => {
    return(
        <>
         <div>
            <label htmlFor={name} className="label">{title}</label><br />
           {options.map(option => (
            <div className="form-check form-check-inline ml-2" key={option}>
             <label className="form-check-label">
            <input type="radio" 
            className="form-check-input" 
            name={name} 
            id={option}
            value={option} 
            required={required}
            defaultChecked={defaultChecked === option ? true : false}
            ref={ref}/>{option}
            
             </label>
            </div>
           ))}
            <p>
            <span className="error">{errors[name]?.message}</span>
            </p>
            </div>
        </>
    )
})

const TextArea = forwardRef(({name, title, defaultValue, placeholder, errors, required}, ref) => {
    return(
        <>
         <div>
            <label htmlFor={name} className="label">{title}</label>
           <textarea className="w3-input w3-border w3-round"
            name={name} type="text"
            placeholder={placeholder}
            required={required}
            rows="5" ref={ref} defaultValue={defaultValue}>
         
           </textarea><br />
            <p>
            <span className="error">{errors[name]?.message}</span>
            </p>
            </div>
        </>
    )
})

const CheckBox = forwardRef(({name, title, errors, options, required, defaultChecked}, ref) => {

    const checker = false;
    return(
        <>
         <div>
            <label htmlFor={name} className="label">{title}</label><br />
           {options.map(option => (
            <div className="form-check form-check-inline ml-2" key={option}>
             <label className="form-check-label">
            <input type="checkbox" 
            className="form-check-input" 
            required={required}
            name={name} 
            id={`${option}-1`}
            value={option} 
            defaultChecked={checker.check(defaultChecked, option)}
            ref={ref}/>{option}
            
             </label>
            </div>
           ))}
            <p>
            <span className="error">{errors[name]?.message}</span>
            </p>
            </div>
        </>
    )
})

const Button =({loading, text}) => {
    return(
        <button className={`w3-btn ${loading ? 'loading' : ''}`}>
                                    
        {loading ? <All.FadeLoader /> : text}
        </button>
    )
}

export {
    Input,
    CheckBox,
    Radio,
    TextArea,
    Button
}