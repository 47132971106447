import { createSlice, createSelector} from '@reduxjs/toolkit';
import {emailScan, phoneAction,
        verifyPhone, schedules,
        login, logout, 
        address} from 'store/actions/userActions';



    
/**
 * users reducer
 */

 const usersSlice = createSlice({
  name: 'users',
  initialState: {
   user: {}, 
   isLogin: sessionStorage.getItem('isLogin') === 'true' || false,
   plans: {},
  },

  reducers: {

  },

  extraReducers: {
    /**
     * 
     * @param {object} state 
     * @param {object} action 
     * set email and regId in session for later use
     */
    [emailScan.fulfilled]: (state, action) => {
    const data = JSON.parse(action.payload.config.data);
    sessionStorage.setItem('regId',  action.payload.data.data.regId);
    sessionStorage.setItem('isNew',  action.payload.data.data.isNew);
    sessionStorage.setItem('email',  data.email);
   
    },

    /**
     * 
     * @param {object} state 
     * @param {object} action 
     * set phone in session to be used for number verification
     */
    [phoneAction.fulfilled]: (state, action) => {
      const data = JSON.parse(action.payload.config.data);
      sessionStorage.setItem('phone', data.phoneNumber)
     
    },

    [verifyPhone.fulfilled]: (state, action) => {
      state.user = action.payload.data.data;
    },

  //  [password.fulfilled]: (state, action) => {
  //     console.log(action.payload)
  //   },

  /**
     * 
     * @param {object} state 
     * @param {object} action 
     * set a session for the login user
     */

    [address.fulfilled]: (state, action) => {
      state.isLogin = true;
      sessionStorage.setItem('isLogin',  true);
    },

    /**
     * 
     * @param {object} state 
     * @param {object} action 
     * set a session isLogin to log user in
     */
    [login.fulfilled]: (state) => {
      state.isLogin = true;
      sessionStorage.setItem('isLogin',  true);
    },

    /**
     * 
     * @param {object} state 
     * @param {object} action 
     * logs user out and clear the session
     */

    [logout.fulfilled]: (state) => {
      state.isLogin = false;
      state = {};
      sessionStorage.clear(); 
    },

    [schedules.fulfilled]: (state, action) => {
     state.plans = action.payload.data.data
    },

    // [getPayUrl.fulfilled]: (state, action) => {
    //   console.log(action.payload.data)
    // },
  }

});

// export const {

// } = usersSlice.actions


export const usersSelector = createSelector(
   (state) => ({
        user: state.users.user,
        isLogin: state.users.isLogin,
        plans: state.users.plans,
    }),

    (state) => state
)

export default usersSlice.reducer
