import NavBar from 'components/public/navbar';
import * as All from 'components/imports';

const LoginLayout = ({children}) => {
    const {loading} = All.useSelector(All.loadersSelector);
    const {error} = All.useSelector(All.errorsSelector);
    return (
        <>
        <NavBar />
        {loading && <All.RotateLoader />}
         {error && <All.ShowError />}
            <div className="login-layout">
                {children}
            </div>
        </>
    )
}


export default LoginLayout;