import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import * as All from 'components/imports';
import * as RouteLinks from 'components/routeLinks';


function LoginAuth({ children, ...rest }) {
const {error} = All.useSelector(All.errorsSelector);
const {isLogin} = All.useSelector(All.usersSelector);

/**
 * checks if user is logged in;
 */

    All.useEffect(() => { 
        return () => {}
    }, [error, isLogin])
    return (
      <Route
        {...rest}
        render={({ location }) =>
         isLogin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: RouteLinks.EmailLogin,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

 export default LoginAuth; 